<template>
  <div>
    <el-card  style="margin-bottom: 20px;">
      <search-filter
          :searchName="'搜索文件'"
          :keywordOptions="{placeholder:'搜索文件名'}"
          :otherOptions="[
              { name:'el-form-item' ,
                  options:{label:'时间' , prop:'date', type:'daterange' , placeholder:'请选择时间'}
                }
          ]"
          :checkedptions="{isShow:false}"
          :isShowHideBtn="false"
          v-model="where"
          :total="total"
          @search="$refs.table.reset(...arguments)"
      ></search-filter>
    </el-card>

    <div class="operate" style="margin-bottom: 15px" v-if="teamUser.is_master">
      <el-button type="primary" @click="$refs.createDialog.visibled(currentData = {})">上传文件</el-button>
    </div>
    <el-card class="table-box">
      <com-table
          ref="table"
          :data="{
            url:'/xapi/user.group/fileList'
            ,params:{team_id}
          }"
          :total.sync="total"
          :columns="[
        {prop:'file_name',label:'文件名',minWidth:'220'},
        {prop:'file_ext',label:'文件格式',width:'120'},
        {prop:'file_size',label:'文件大小',width:'120'},
        {prop:'create_date',label:'上传时间',width:'160' },
        {prop:'user_nickname',label:'上传人',width:'160'},
        {fixed:'right',label:'操作',width:'180' , slot:'action' , align:'center',hidden:!(teamUser.is_master)},
      ]"
          :where="where"
      >
        <el-button-group slot="action" slot-scope="{row}">
          <!-- <el-button type="text" :disabled="row.user_id !== userInfo.id" size="small" @click="$refs.createDialog.visibled(currentData = {...row,file_url:[{url:row.file_url,name:row.file_name+row.file_ext}]})">编辑</el-button> -->
          <el-button type="text" :disabled="row.user_id !== userInfo.id" size="small" @click="editFile(row)">编辑</el-button>
          <el-button type="text" size="small" @click="handleCommand('confirm','你确定下载文件吗？',(action)=>{action === 'confirm' && $tool.fileResponse(row.id||row.url,{},{},{message:true});} ,{title:'下载文件'})">下载</el-button>
          <el-button type="text" :disabled="row.user_id !== userInfo.id" size="small" @click="handleCommand('confirm','你确定删除文件吗？','/xapi/user.group/fileDel',{team_id,file_id:row.id},()=>{$refs.table.reset(where);} ,{title:'删除文件'})">删除</el-button>
        </el-button-group>
      </com-table>
    </el-card>

    <diy-dialog ref="createDialog" width="460px" title="上传文件" center>
      <form-create ref="createForm" v-model="currentData" :components="childrens" :label-width="'100px'" @update="createFormUpdate">
        <span slot="trigger"></span>
        <div slot="content" style="display: inline-block;" slot-scope="{handTrigger,fileList}">
          <el-button style="margin-right: 10px;" icon="el-icon-upload" size="mini" @click="handTrigger">{{ (fileList || []).length > 0 ? '重新上传':'上传文件'}}</el-button>
          <span>支持CSV格式</span>
        </div>
      </form-create>
      <!-- <form-create ref="createForm" v-model="currentData" :components="[
        {name:'form-item',props:{prop:'file_name',label:'文件名称',required:true }},
        {name:'form-item',props:{prop:'file_url',label:'选择文件',required:true,type:'upload' ,limit:1,'list-type':'text','show-file-list':true,
          
        },slots:{trigger:true,content:true}},
        {name:'form-item',props:{prop:'scope',label:'设置范围',defaultValue:1,required:true,type:'select',data:[{label:'私有',value:1},{label:'公开',value:2}]}},
        {name:'form-item',props:{prop:'user_ids',label:'', type:'select',multiple:true,filterable:false,data:{ url:'/xapi/user.group/memberList',params:{team_id}, labelOptions:{value:'user_id',label:'realname'} }},condition:'{{scope}}==1'},
          ]" :label-width="'100px'" @update="createFormUpdate">
        <span slot="trigger"></span>
        <div slot="content" style="display: inline-block;" slot-scope="{handTrigger,fileList}">
          <el-button style="margin-right: 10px;" icon="el-icon-upload" size="mini" @click="handTrigger">{{ (fileList || []).length > 0 ? '重新上传':'上传文件'}}</el-button>
          <span>支持CSV格式</span>
        </div>
      </form-create> -->
      
      <div slot="footer">
        <el-button style="margin-right: 50px;" type="primary" @click="$refs.createDialog.visibled(false)">取 消</el-button>
        <el-button type="primary" @click="$refs.createForm.validate((data,valid)=>{valid && handleCommand('request','/xapi/user.group/fileSave',{team_id,id:currentData.id,...data,...info},()=>{$refs.table.reset(where);$refs.createDialog.visibled(false);} ,{title:'下载文件'})},true)">确 定</el-button>
      </div>
    </diy-dialog>
  </div>
</template>

<script>
import SearchFilter from "@/components/com/SearchFilter";
export default {
  props: {
    team_id:{
      default: () => 0,
    },
    teamUser:{
      type:Object,
      default: () => { return {}; },
    },
  },
  components: {SearchFilter},
  data(){
    var _this = this;
    return {
      total:0,
      where:{},
      currentData:{},
      info:{},
      fileNameAuto:true,
      childrens:[
        {name:'form-item',props:{prop:'file_name',label:'文件名称',required:true }},
        {name:'form-item',props:{prop:'file_url',label:'选择文件',required:true,type:'upload' ,limit:1,'list-type':'text','show-file-list':true,
           onSuccess:(e)=>{
              console.log(e,"-----------eee");
              _this.info={
                // name:e.data.name,
                file_type:e.data.type,
                file_size:e.data.size,
              }
            }
        },slots:{trigger:true,content:true}},
        {name:'form-item',props:{prop:'scope',label:'设置范围',defaultValue:1,required:true,type:'select',data:[{label:'私有',value:1},{label:'公开',value:2}]}},
        {name:'form-item',props:{prop:'user_ids',label:'', type:'select',multiple:true,filterable:false,data:{ url:'/xapi/user.group/memberList',params:{team_id:this.team_id,pageSize:100}, labelOptions:{value:'user_id',label:'realname'} }},condition:'{{scope}}==1'},
          ]
    }
  },
  created() {
    this.fileNameAuto = true;
    // console.log(this.team_id,"team_id");
    console.log(this.currentData,"111111111");

  },
  methods:{
    editFile(row){
      console.log(row,"roww");
      this.$refs.createDialog.visibled(true)
      this.currentData=row
      this.info.file_size=row.size
      this.info.file_type=row.file_type
      console.log(this.info,"00000000");
    },
    createFormUpdate({prop,actValue , vNode}){
      console.log(vNode,"vNode");
      console.log(actValue,"actValue");
      if(prop === 'file_name'){
        this.fileNameAuto = false;
      }
      if(prop === 'file_url' && this.fileNameAuto && actValue){
        let file_name = (((vNode || {}).acValue || actValue || [])[0] || {}).name
        if(file_name){
          this.currentData.file_name = file_name.substr(0,file_name.lastIndexOf('.'))
          this.fileNameAuto = true;
        }
      }
    },
  },
}
</script>

<style lang="less" scoped>

/deep/ .table-box{
  .el-card__header{
    padding: 0;
  }
  .title{
    height:48px;
    line-height: 24px;
  }
  .price{
    font-size: 90%;
    line-height: 1.5;
    color: rgba(254,81,52,1);
  }
  .desc{
    margin-top: 10px;
    font-size: 90%;
    line-height: 1.5;
    color: rgb(136, 136, 136);
  }
}
</style>